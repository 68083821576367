import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import config from "../config.json"

const AvatarBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 15%;
  `

const TopSpacing = styled.div`
    height:15vh;
    width:100%;
`

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      password: '',
      login: false
    }

    localStorage.clear();
  }

  handleFormSubmit = e => {
    e.preventDefault();
    var request = {
      method: "post",
      baseURL: config.api_url,
      url: "/users/login",
      data: {
        username: this.state.name,
        password: this.state.password
      }
    };

    axios(request).then((response) => {
      if (!response.data.token && response.data.message) {
        window.alert(response.data.message)
      } else {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', response.data.user.username);
        localStorage.setItem('user_id', response.data.user._id);
        localStorage.setItem('is_admin', response.data.user.is_admin);
        this.setState({ login: response.data.token !== undefined })
      }
    }).catch((error) => {
      console.log("ERROR")
      window.alert(error.response.data.message)
    });
  }

  render() {
    if (this.state.login) {
      return <Redirect to='/control' />;
    }

    return (
      <Container>
        <div>
          <TopSpacing />
          <AvatarBox>
            <Avatar>
              <LockOutlinedIcon />
            </Avatar>
          </AvatarBox>
          <AvatarBox>
            <Typography component="h1" variant="h5">Sign in</Typography>
          </AvatarBox>
          <form noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="User Name"
              name="name"
              autoFocus
              onChange={e => this.setState({ name: e.target.value })}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={e => this.setState({ password: e.target.value })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={e => this.handleFormSubmit(e)}
            >
              Sign In
            </Button>
            <Grid container>
              {/*<Grid item xs>*/}
              {/*  <Link href="#" variant="body2">*/}
              {/*    Forgot password?*/}
              {/*  </Link>*/}
              {/*</Grid>*/}
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    );
  }
}

export default Login;
