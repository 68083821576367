import NoteScreen from "../note_screen/NoteScreen";
import StartTaskBox from "../note_screen/StartTaskBox";

export default function PreTrainingControl(props) {
    let desc_text = ""
    let header_text = ""

    if (props.phase === 1) {
        desc_text += "Welcome to phase one of the annotation task. "
        desc_text += "In the following screens, you will be presented with several fact-checking articles. "
        desc_text += "The assigned task is to collect metadata from these articles. "
        desc_text += "Please refer to the annotation guideline document for precise instructions on how to complete the task. "
        desc_text += "As in the warm-up round, you will be asked to annotate several examples. "
        desc_text += "During the training phase, you will first be presented with five warm-up claims. "
        desc_text += "Then, you will be able to compare your answers to a set of reference annotation we have compiled. "
        desc_text += "After reviewing the reference annotations, you will then be asked to annotate another five examples which will be used to evaluate your performance. "

        header_text = "Averitec Training | Phase One"
    } else if (props.phase === 2) {
        desc_text += "Welcome to phase two of the annotation task. "
        desc_text += "In the following screens, you will be presented with several fact-checking articles. "
        desc_text += "Your fellow annotators have already extracted claims and collected metadata about these articles. "
        desc_text += "Your task is to create question-answer pairs that allow you to conclude whether the fact is support by available evidence. "
        desc_text += "The fact-checking article can be used as inspiration for which questions to ask. "
        desc_text += "Please refer to the annotation guideline document for precise instructions on how to complete the task. "
        desc_text += "During the training phase, you will first be presented with five warm-up claims. "
        desc_text += "Then, you will be able to compare your answers to a set of reference annotation we have compiled. "
        desc_text += "After reviewing the reference annotations, you will then be asked to annotate another five examples which will be used to evaluate your performance. "

        header_text = "Averitec Training | Phase Two"
    } else if (props.phase === 3) {
        desc_text += "Welcome to phase three of the annotation task. "
        desc_text += "In the following screens, you will be presented with several claims along with question-answer pairs generated by your fellow annotators. "
        desc_text += "Your task is to determine whether the question-answer pairs support or refute the claims. "
        desc_text += "In this phase, you will NOT see the fact-checking article, and you should NOT search for any additional evidence beyond what the question-answer pairs present. "
        desc_text += "If something is wrong with a question-answer pair, you will also be able to flag it. "
        desc_text += "Please refer to the annotation guideline document for precise instructions on how to complete the task. "
        desc_text += "During the training phase, you will first be presented with five warm-up claims. "
        desc_text += "Then, you will be able to compare your answers to a set of reference annotation we have compiled. "
        desc_text += "After reviewing the reference annotations, you will then be asked to annotate another five examples which will be used to evaluate your performance. "

        header_text = "Averitec Training | Phase Three"
    }

    return <NoteScreen header={header_text}>
        <StartTaskBox taskLink={props.taskLink}>
            {desc_text}
        </StartTaskBox>
    </NoteScreen>;
}