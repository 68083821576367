import Card from '@material-ui/core/Card';
import styled from 'styled-components';
import * as react from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import config from "../config.json"
import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip, LineChart, Line, Legend, ResponsiveContainer,
    BarChart, Bar, Cell
} from "recharts";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import md5 from 'md5';
import ClearIcon from '@material-ui/icons/Clear';
import Spinner from 'react-spinkit';

const StyledSpinner = styled(Spinner)`
    width:80px;
    height:80px;
    border: 1px solid transparent;
    display: block;

    margin: 20px 0px 20px -webkit-calc(50% - 40px)!important;
    margin: 20px 0px 20px -moz-calc(50% - 40px)!important;
    margin: 20px 0px 20px calc(50% - 40px)!important;
`

const StyledPopup = styled(Popup)` 
    &-content {    
        width:290px!important;
    }
`

const AssignChartBox = styled("div")`
    float: right;
    width: 30%;
    height: 0px;
`

const ModalPartBox = styled("div")`
    width:100%;
    margin:10px;
`

const CancelButton = styled(IconButton)`
    float: right;
    width:40px;
    margin: -10px 0px!important;
`

const AssignRadioBox = styled("div")`
    float: left;
    width: 30%;
`

const AssignControlBox = styled("div")`
    float: left;
    width: 100%;
`

const AssignControlTextBox = styled("div")`
    float: left;
    width: 100%;
    margin: 10px 0px;
    line-height: 210%;
`

const EntryCard = styled(Card)`
    margin:10px;
    padding: 0px 20px 5px 20px;
`

const Header = styled.h4`
`

const AddButton = styled(Button)`
float:left;
width:200px;
margin:10px 0px!important;
`

const JsonButton = styled(Button)`
float:left;
width:200px;
margin:10px 0px 10px 10px!important;
`

const DeleteButton = styled(Button)`
float:right;
width:200px;
margin:10px 0px!important;
`

const generatePassword = () => {
    var length = 8;
    var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var retVal = "";

    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    return retVal;
}

const propComparator = (p1, p2) => {
    let p1_n = parseInt(p1.split(" / ")[0])
    let p2_n = parseInt(p2.split(" / ")[0])

    return p1_n - p2_n
}


class AdminControl extends react.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: [],
            availableClaimsData: [
                {
                    name: "Unassigned Claims",
                    p1: 0,
                    p2: 0,
                    p3: 0,
                    p4: 0,
                    p5: 0,
                },
            ],
            assignment: {
                assignment_type: "non_admin",
                assignment_phase: 1
            },
            new_username: "",
            n_users_to_create: 1,
            processing_assignment: true,
            show_type: 0,
        }

        this.cellEdit = this.cellEdit.bind(this);
        this.addRow = this.addRow.bind(this);
        this.addUser = this.addUser.bind(this);
        this.makeNewRow = this.makeNewRow.bind(this);
        this.deleteRows = this.deleteRows.bind(this);
        this.setSelectedRows = this.setSelectedRows.bind(this);
        this.handleAssignFieldChange = this.handleAssignFieldChange.bind(this);
        this.assignMax = this.assignMax.bind(this);
        this.doAssign = this.doAssign.bind(this);
        this.getMax = this.getMax.bind(this);
        this.bulkAddUsers = this.bulkAddUsers.bind(this);
    }

    getMax() {
        var phase_id = "p1"
        if (this.state.assignment && this.state.assignment.assignment_phase) {
            phase_id = "p" + this.state.assignment.assignment_phase;
        }

        if (this.state.table) {
            var user_count = this.state.table.length

            if (this.state.assignment && this.state.assignment.assignment_type === "non_admin") {
                user_count = this.state.table.filter(user => !user.is_admin).length;
            }

            if (this.state.assignment && this.state.assignment.assignment_type === "all") {
                user_count = this.state.table.length
            }

            if (this.state.assignment && this.state.assignment.assignment_type === "selection") {
                user_count = this.state.selected ? this.state.selected.length : 0
            }

            var total_to_assign = this.state.availableClaimsData[0][phase_id]

            var per_user = 0
            if (user_count > 0) {
                per_user = Math.ceil(total_to_assign / user_count)
            }

            return per_user;
        } else {
            return 0;
        }
    }

    assignMax() {
        if (this.state.table) {
            let per_user = this.getMax()

            this.setState(prevState => ({
                assignment: {
                    ...prevState.assignment,
                    n_to_assign: per_user
                }
            }));
        }
    }

    doAssign() {
        var uids = null

        if (this.state.assignment.assignment_type != "selection") {
            uids = []

            this.state.table.forEach(row => {
                if (this.state.assignment.assignment_type === "all" || !row.is_admin) {
                    uids = [
                        ...uids,
                        row.id
                    ]
                }
            })
        }

        let request_n = this.state.assignment.n_to_assign < 0? -this.state.assignment.n_to_assign : this.state.assignment.n_to_assign
        let request_url = this.state.assignment.n_to_assign < 0? "/assignment/unassign_from_list" : "/assignment/assign_to_list"
        

        var request = {
            method: "post",
            baseURL: config.api_url,
            url: request_url,
            data: {
                assignments_per_user: request_n,
                user_ids: this.state.assignment.assignment_type === "selection" ? this.state.selected : uids,
                phase: this.state.assignment.assignment_phase
            },
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        };


        this.setState({
            processing_assignment: true
        }, () => {
            axios(request).then((response) => {
                console.log(response.data);
                window.location.reload(false);
            }).catch((error) => { window.alert(error) })
        });
    }

    handleAssignFieldChange(event) {
        var { name, value } = event.target;

        if (name === "n_to_assign") {
            const re = /^\-?[0-9\b]+$/;
            if (value != '' && !re.test(value)) {
                return;
            }

            value = parseInt(value, 10) - 0

            let max_per_user = this.getMax();
            value = Math.min(value, max_per_user);
        }

        this.setState(prevState => ({
            assignment: {
                ...prevState.assignment,
                [name]: value
            }
        }), () => {
            if (name != "n_to_assign") {
                let max_per_user = this.getMax();
                if (this.state.assignment.n_to_assign && this.state.assignment.n_to_assign > max_per_user) {
                    this.setState(prevState => ({
                        assignment: {
                            ...prevState.assignment,
                            n_to_assign: max_per_user
                        }
                    }));
                }
            }
        });
    }

    loadTableFromDB() {
        if (this.props.name == "Users") {
            // TODO: set availableClaimsData via API call.
            var request = {
                method: "get",
                baseURL: config.api_url,
                url: "/annotations/pending_by_phase",
                data: {
                },
                headers: {
                    'Authorization': localStorage.getItem('token')
                }
            };

            axios(request).then((response) => {
                this.setState({
                    availableClaimsData: [
                        {
                            name: "Unassigned Claims",
                            p1: response.data.phase_1,
                            p2: response.data.phase_2,
                            p3: response.data.phase_3,
                            p4: response.data.phase_4,
                            p5: response.data.phase_5,
                        },
                    ],
                })

            }).catch((error) => { window.alert(error) });

            var cutoff_date = null;

            if (this.state.show_type === 1) {
                // Cutoff at last sunday, 23:59:59
                cutoff_date = new Date();
                cutoff_date.setDate(cutoff_date.getDate() - cutoff_date.getDay());
                cutoff_date.setHours(23, 59, 59, 999);
            } else if (this.state.show_type === 2) {
                // Cutoff at last day of last month, 23:59:59
                cutoff_date = new Date();
                cutoff_date.setDate(0);
                cutoff_date.setHours(23, 59, 59, 999);
            }

            var upper_cutoff_date = null;
            if (this.state.show_type === 3) {
                // Cutoff at sunday before last sunday, 23:59:59
                cutoff_date = new Date();
                cutoff_date.setDate(cutoff_date.getDate() - cutoff_date.getDay() - 7);
                cutoff_date.setHours(23, 59, 59, 999);

                // Upper cutoff at last sunday, 23:59:59
                upper_cutoff_date = new Date();
                upper_cutoff_date.setDate(upper_cutoff_date.getDate() - upper_cutoff_date.getDay());
                upper_cutoff_date.setHours(23, 59, 59, 999);
            }

            console.log("Cutoff at: " + cutoff_date)

            var request = {
                method: "post",
                baseURL: config.api_url,
                url: "/users/all",
                data: {
                    stats_date_cutoff: cutoff_date,
                    stats_upper_date_cutoff: upper_cutoff_date,
                },
                headers: {
                    'Authorization': localStorage.getItem('token')
                }
            };

            axios(request).then((response) => {
                var user_data = response.data.users
                user_data = user_data.map(user_dict => {
                    let new_dict = {
                        ...user_dict.statistics,
                        username: user_dict.username,
                        id: user_dict._id,
                        is_admin: user_dict.is_admin,
                        total_minutes_working: user_dict.statistics.total_time_spent / 60,
                        total_minutes_training: user_dict.statistics.total_time_spent_training / 60,
                        total_minutes_loading: user_dict.statistics.total_time_loading / 60,
                        average_questions_p2: user_dict.statistics.phase_2.average_questions,
                        average_questions_p4: user_dict.statistics.phase_4.average_questions,
                    }

                    for (let index = 1; index <= 5; index++) {
                        let phase_id = "phase_" + index
                        if (this.state.show_type === 0) {
                            new_dict["finished_" + phase_id + "_prop"] = new_dict[phase_id]["annotations_done"] + " / " + new_dict[phase_id]["annotations_assigned"]
                        } else {
                            new_dict["finished_" + phase_id + "_prop"] = new_dict[phase_id]["annotations_done"]

                        }
                        new_dict["finished_" + phase_id + "_per_hour"] = new_dict[phase_id]["annotations_per_hour"] ? new_dict[phase_id]["annotations_per_hour"].toFixed(2) : ""
                    }

                    for (let index = 1; index <= 3; index++) {
                        let phase_id = "phase_" + index + "_training"
                        if (this.state.show_type === 0) {
                            new_dict["finished_" + phase_id + "_prop"] = new_dict[phase_id]["annotations_done"] + " / " + new_dict[phase_id]["annotations_assigned"]
                        } else {
                            new_dict["finished_" + phase_id + "_prop"] = new_dict[phase_id]["annotations_done"]

                        }
                    }

                    return new_dict
                });
                // ----------- 

                this.setState({
                    header: [
                        {
                            field: "id",
                            headerName: "ID",
                            width: 240,
                            renderCell: (cellValues) => {
                                return <a href={`/user?id=${cellValues.row.id}`}>{cellValues.row.id}</a>;
                            }
                        },
                        { field: "username", headerName: "Name", editable: true, width: 200 },
                        { field: "is_admin", headerName: "Admin", editable: true, type: "boolean", width: 150 },
                        { field: "finished_phase_1_prop", headerName: "Phase 1 Finished", type: "string", editable: false, width: 250, sortComparator: propComparator, align: "right", headerAlign: "right" },
                        { field: "finished_phase_2_prop", headerName: "Phase 2 Finished", type: "string", editable: false, width: 250, sortComparator: propComparator, align: "right", headerAlign: "right" },
                        { field: "finished_phase_3_prop", headerName: "Phase 3 Finished", type: "string", editable: false, width: 250, sortComparator: propComparator, align: "right", headerAlign: "right" },
                        { field: "finished_phase_1_training_prop", headerName: "Phase 1 Training Finished", type: "string", editable: false, width: 250, sortComparator: propComparator, align: "right", headerAlign: "right" },
                        { field: "finished_phase_2_training_prop", headerName: "Phase 2 Training Finished", type: "string", editable: false, width: 250, sortComparator: propComparator, align: "right", headerAlign: "right" },
                        { field: "finished_phase_3_training_prop", headerName: "Phase 3 Training Finished", type: "string", editable: false, width: 250, sortComparator: propComparator, align: "right", headerAlign: "right" },
                        { field: "finished_phase_4_prop", headerName: "Phase 4 Finished", type: "string", editable: false, width: 250, sortComparator: propComparator, align: "right", headerAlign: "right" },
                        { field: "finished_phase_5_prop", headerName: "Phase 5 Finished", type: "string", editable: false, width: 250, sortComparator: propComparator, align: "right", headerAlign: "right" },
                        { field: "finished_phase_1_per_hour", headerName: "Phase 1 Rate (A/h)", type: "number", editable: false, width: 250, align: "right", headerAlign: "right" },
                        { field: "finished_phase_2_per_hour", headerName: "Phase 2 Rate (A/h)", type: "number", editable: false, width: 250, align: "right", headerAlign: "right" },
                        { field: "finished_phase_3_per_hour", headerName: "Phase 3 Rate (A/h)", type: "number", editable: false, width: 250, align: "right", headerAlign: "right" },
                        { field: "finished_phase_4_per_hour", headerName: "Phase 4 Rate (A/h)", type: "number", editable: false, width: 250, align: "right", headerAlign: "right" },
                        { field: "finished_phase_5_per_hour", headerName: "Phase 5 Rate (A/h)", type: "number", editable: false, width: 250, align: "right", headerAlign: "right" },
                        { field: "total_minutes_working", headerName: "Total Minutes Working (incl. Loading)", type: "number", editable: false, width: 220 },
                        { field: "total_minutes_training", headerName: "Total Minutes Training", type: "number", editable: false, width: 220 },
                        { field: "total_minutes_loading", headerName: "Total Minutes Loading", type: "number", editable: false, width: 220 },
                        { field: "total_skips", headerName: "Total Pages Skipped", type: "number", editable: false, width: 220 },
                        { field: "total_timeouts", headerName: "Page Timeouts", type: "number", editable: false, width: 220 },
                        { field: "total_speed_traps", headerName: "Speed traps hit", type: "number", editable: false, width: 220 },
                        { field: "average_questions_p2", headerName: "Average P2 Questions", type: "number", editable: false, width: 220 },
                        { field: "average_questions_p4", headerName: "Average P4 Questions", type: "number", editable: false, width: 220 },
                    ],
                    table: user_data
                })


            }).catch((error) => { window.alert(error) })
        }
    }

    componentDidMount() {
        this.loadTableFromDB()


        // Only execute the following setState if no assignment is currently in progress.
        this.setState({
            processing_assignment: false
        });

        // IF NECESSARY:

        // Use this to test if server is done every 5 sec
        // this.interval = setInterval(() => this.tick(), 5000);

        // Use this to stop checking
        // clearInterval(this.interval);
    }

    cellEdit(params, event) {
        console.log(`Editing cell with row id: ${params.id} and column: ${params.field} to have value: ${params.value}, triggered by ${event.type}.`)


        if (this.props.name == "Users") {
            var request = {
                method: "post",
                baseURL: config.api_url,
                url: "/users/update/" + params.id,
                data: {
                    [params.field]: params.value
                },
                headers: {
                    'Authorization': localStorage.getItem('token')
                }
            };

            axios(request).then((response) => {
                console.log(response.data);
                this.loadTableFromDB();
            }).catch((error) => { window.alert(error) })
        }
    }

    makeNewRow() {
        // This should be an API call creating a new entry in the approppriate table. Then, we should reload the entire table. That way, if there is a mistake/lost connection to the server/etc, the state will not falsely update.
        // We don't use this since the only table we render is users, so please ignore
        return { id: this.state.table.length + 1 }
    }

    deleteRows() {
        console.log(`Delete entries by ID: ` + JSON.stringify(this.state.selected))

        if (this.props.name == "Users") {
            var request = {
                method: "post",
                baseURL: config.api_url,
                url: "/users/deactivate_many",
                data: {
                    user_ids: this.state.selected
                },
                headers: {
                    'Authorization': localStorage.getItem('token')
                }
            };

            axios(request).then((response) => {
                console.log(response.data);
                this.loadTableFromDB();
            }).catch((error) => { window.alert(error) })
        }
    }

    setSelectedRows(rows) {
        this.setState({
            selected: rows
        }, () => {
            if (this.props.name === "Users" && this.state.assignment.assignment_type === "selection") {
                let max_per_user = this.getMax();
                if (this.state.assignment.n_to_assign > max_per_user) {
                    this.setState(prevState => ({
                        assignment: {
                            ...prevState.assignment,
                            n_to_assign: max_per_user
                        }
                    }));
                }
            }
        })
    }

    addRow() {
        this.setState({
            table: [
                ...this.state.table,
                this.makeNewRow()
            ]
        })
    }

    bulkAddUsers() {
        let generated_users = {}
        for (let index = 0; index < this.state.n_users_to_create; index++) {
            let username = "user_" + (index + this.state.table.length * 10)
            let password = generatePassword();

            generated_users = {
                ...generated_users,
                [username]: password
            }

            var request = {
                method: "post",
                baseURL: config.api_url,
                url: "/users/register",
                data: {
                    username: username,
                    password: password,
                },
                headers: {
                    'Authorization': localStorage.getItem('token')
                }
            };

            axios(request).then((response) => {
                console.log(response.data);
                if (index === this.state.n_users_to_create - 1) {
                    this.loadTableFromDB();
                }
            }).catch((error) => { window.alert(error) })
        }

        const fileName = "generated_users";
        const json = JSON.stringify(generated_users);
        const blob = new Blob([json], { type: 'application/json' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    addUser() {
        let username = this.state.new_username;
        let password = this.state.tempPassword;

        // Create user
        // Warning: I copypasted this code from registration
        var request = {
            method: "post",
            baseURL: config.api_url,
            url: "/users/register",
            data: {
                username: username,
                password: password,
            },
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        };

        axios(request).then((response) => {
            console.log(response.data);
            this.loadTableFromDB();
        }).catch((error) => { window.alert(error) })

        this.setState({ new_username: "" })
        this.setState({ tempPassword: "" })
    }

    render() {
        let className = ''

        if (this.props.className !== undefined) {
            className = this.props.className
        }

        let datagrid = ""

        if (this.state.header) {
            datagrid = <DataGrid
                rows={this.state.table}
                columns={this.state.header}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
                disableSelectionOnClick
                onCellEditCommit={this.cellEdit}
                onSelectionModelChange={this.setSelectedRows}
            />
        }

        var assign_count = 0

        var phase_id = "p1"
        if (this.state.assignment && this.state.assignment.assignment_phase) {
            phase_id = "p" + this.state.assignment.assignment_phase;
        }

        if (this.state.table) {
            var user_count = this.state.table.length // TODO: count non-admins

            if (this.state.assignment && this.state.assignment.assignment_type === "all") {
                user_count = this.state.table.length
            }

            if (this.state.assignment && this.state.assignment.assignment_type === "selection") {
                user_count = this.state.selected.length
            }

            if (this.state.assignment && this.state.assignment.n_to_assign) {
                assign_count = Math.min(this.state.availableClaimsData[0][phase_id], this.state.assignment.n_to_assign * user_count);
            }
        }

        var hackedDivHeight = 55 * ((this.state.table) ? ((this.state.table.length > 10) ? 10 : this.state.table.length) + 2 : 0) + 10

        return (
            <div className={className}>
                <EntryCard>
                    <Header>{this.props.name}</Header>
                    <div style={{ height: hackedDivHeight, width: '100%' }}>
                        {datagrid}
                        {this.props.name === "Users" ?
                            <div>
                                <StyledPopup trigger={
                                    <AddButton variant="contained" color="primary">
                                        Create User
                                    </AddButton>
                                }
                                    onOpen={() => {
                                        let password = generatePassword()
                                        console.log(password)
                                        this.setState({ tempPassword: password })
                                    }}
                                    modal
                                >
                                    {(close) =>
                                        <div>
                                            <CancelButton onClick={close}><ClearIcon /></CancelButton>
                                            <ModalPartBox>
                                                Create a new user. Username:
                                            </ModalPartBox>
                                            <ModalPartBox>
                                                <TextField value={this.state.new_username} size="small" name="new_username" onChange={(event) => {
                                                    this.setState({ new_username: event.target.value })
                                                }}></TextField>
                                            </ModalPartBox>
                                            <ModalPartBox>
                                                Temporary password:
                                            </ModalPartBox>
                                            <ModalPartBox>
                                                <TextField value={this.state.tempPassword ? this.state.tempPassword : ""} size="small" name="new_password" onChange={(event) => {this.setState(
                                                    {tempPassword: event.target.value})}}></TextField>
                                            </ModalPartBox>
                                            <ModalPartBox>
                                                <AddButton variant="contained" color="primary" onClick={() => { this.addUser(); close(); }}>
                                                    Create User
                                                </AddButton>
                                            </ModalPartBox>
                                        </div>}
                                </StyledPopup>
                                <StyledPopup trigger={
                                    <JsonButton variant="contained" color="primary">
                                        Bulk create users
                                    </JsonButton>
                                }
                                    onOpen={() => {
                                        let password = generatePassword()
                                        console.log(password)
                                        this.setState({ tempPassword: password })
                                    }}
                                    modal
                                >
                                    {(close) =>
                                        <div>
                                            <CancelButton onClick={close}><ClearIcon /></CancelButton>
                                            <ModalPartBox>
                                                Generate several new user accounts. Number to create:
                                            </ModalPartBox>
                                            <ModalPartBox>
                                                <TextField value={this.state.n_users_to_create} size="small" name="n_users_to_create" onChange={(event) => {
                                                    this.setState({ n_users_to_create: event.target.value })
                                                }}></TextField>
                                            </ModalPartBox>
                                            <ModalPartBox>
                                                <AddButton variant="contained" color="primary" onClick={() => { this.bulkAddUsers(); close(); }}>
                                                    Create Users
                                                </AddButton>
                                            </ModalPartBox>
                                        </div>}
                                </StyledPopup>
                            </div>
                            :
                            <AddButton variant="contained" color="primary" onClick={this.addRow}>
                                Create Row
                            </AddButton>
                        }
                        <JsonButton
                            variant="contained"
                            color="primary"
                            type="button"
                            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                JSON.stringify(this.state.table)
                            )}`}
                            download={this.props.name + ".json"}
                        >
                            Download as JSON
                        </JsonButton>
                        <FormControl
                            size="small"
                            sx={{
                                margin: "8px",
                                width: 200
                            }}>
                            <InputLabel shrink>Display</InputLabel>
                            <Select label={"show_type"} value={this.state.show_type} onChange={
                                (event) => {
                                    console.log(this.state.show_type)
                                    this.setState({ show_type: event.target.value }, () => {
                                        this.loadTableFromDB()
                                    });
                                }
                            }>
                                <MenuItem value={0}>All time</MenuItem>
                                <MenuItem value={1}>This week</MenuItem>
                                <MenuItem value={2}>This month</MenuItem>
                                <MenuItem value={3}>Last week</MenuItem>
                            </Select>
                        </FormControl>

                        <DeleteButton variant="contained" color="error" startIcon={<DeleteIcon />} onClick={this.deleteRows}>
                            Delete Selected
                        </DeleteButton>
                    </div>
                </EntryCard>
                {this.props.name === "Users" ?
                    <EntryCard>
                        <Header>Assignments</Header>
                        {this.state.processing_assignment ? <StyledSpinner
                            name="circle"
                        /> : <div>
                            <AssignChartBox>
                                <BarChart
                                    width={100 + this.state.availableClaimsData.length * 350}
                                    height={300}
                                    data={this.state.availableClaimsData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar barSize={60} name="Claim Normalization" dataKey="p1" fill="#8884d8" />
                                    <Bar barSize={60} name="Question Generation" dataKey="p2" fill="#8dd1e1" />
                                    <Bar barSize={60} name="Quality Control" dataKey="p3" fill="#82ca9d" />
                                    <Bar barSize={60} name="Dispute Resolution" dataKey="p4" fill="#d0ed57" />
                                    <Bar barSize={60} name="Post-Resolution Quality Control" dataKey="p5" fill="#ffc658" />
                                </BarChart>
                            </AssignChartBox>

                            <AssignRadioBox>
                                <FormLabel component="type_legend">Phase:</FormLabel>
                                <RadioGroup onChange={this.handleAssignFieldChange} aria-label="assignment_phase" name="assignment_phase" value={this.state.assignment && this.state.assignment.assignment_phase ? this.state.assignment.assignment_phase : 1}>
                                    <FormControlLabel value="1" control={<Radio />} label="Claim Normalization" />
                                    <FormControlLabel value="2" control={<Radio />} label="Question Generation" />
                                    <FormControlLabel value="3" control={<Radio />} label="Quality Control" />
                                    <FormControlLabel value="4" control={<Radio />} label="Dispute Resolution" />
                                    <FormControlLabel value="5" control={<Radio />} label="Post-Resolution Quality Control" />
                                </RadioGroup>
                            </AssignRadioBox>

                            <AssignRadioBox>
                                <FormLabel component="type_legend">Assign to:</FormLabel>
                                <RadioGroup onChange={this.handleAssignFieldChange} aria-label="assignment_type" name="assignment_type" value={this.state.assignment && this.state.assignment.assignment_type ? this.state.assignment.assignment_type : "non_admin"}>
                                    <FormControlLabel value="non_admin" control={<Radio />} label="All Non-admins" />
                                    <FormControlLabel value="selection" control={<Radio />} label="Selected Users" />
                                    <FormControlLabel value="all" control={<Radio />} label="All Users" />
                                </RadioGroup>
                            </AssignRadioBox>

                            <AssignControlTextBox>
                                Assign (up to) <TextField value={this.state.assignment && (this.state.assignment.n_to_assign || this.state.assignment.n_to_assign === 0) ? this.state.assignment.n_to_assign : ""} size="small" name="n_to_assign" type="number" onChange={this.handleAssignFieldChange}></TextField> claims to each user.
                                {this.state.table ? " A total of " + assign_count + " claims will be assigned." : ""}
                            </AssignControlTextBox>

                            <AssignControlBox>
                                <AddButton variant="contained" onClick={this.assignMax} color="secondary">
                                    Max
                                </AddButton>
                                {this.state.assignment.n_to_assign && !(this.state.assignment.assignment_type === "selection" && this.state.selected.length === 0) ?
                                    <JsonButton variant="contained" onClick={this.doAssign} color="primary">
                                        Assign
                                    </JsonButton>
                                    :
                                    <JsonButton variant="contained" disabled color="primary">
                                        Assign
                                    </JsonButton>
                                }
                            </AssignControlBox>
                        </div>}
                    </EntryCard>
                    :
                    ""}
            </div>
        );
    }
}

export default AdminControl;
